import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import resume from '../assets/CV/Sagar-Satam-CV2019.pdf'

import wo1 from '../assets/images/wo1.png'
import clientgroup from '../assets/images/clientgroup.jpg'
import about1 from '../assets/images/about/about1.jpg'
import about2 from '../assets/images/about/about2.png'
import about5 from '../assets/images/about/about5.jpg'
import about7 from '../assets/images/about/about7.png'
import about8 from '../assets/images/about/about8.jpg'
import about10 from '../assets/images/about/about10.png'
import awardsgroup from '../assets/images/awardsgroup.png'
import mediumlogo from '../assets/images/mediumlogo.png'



import { Link } from 'gatsby'

const WhatOffer = props => (
  <Layout>
    <Helmet
      title="Design approach | Portfolio Sagar Satam"
      meta={[
        { name: 'Design', content: 'Portfolio' },
        { name: 'Design', content: 'product design, designer' },
      ]}
    />

    <div id="main" className="alt wo">
      <section id="title-section" className="title-block inner">
        <div className="hp-featured">
          {/* <h1 className="hp-featured__title">
            my design approach
          </h1> */}
          {/* <div className="hp-featured__content">
            <h4 className="hp-featured__text caps">
              SERVICE DESIGN & UX STRATEGY
            </h4>
            <p className="hp-featured__text">
              As part of a team, I can contribute in various ways to design
              products, service experiences and brands.
            </p>
          </div> */}
        </div>
      </section>

      <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
            
            <div className="bluebg">
            <br />
            <p className="full-textlargebluebg">
            {/* We design from who we are.
            <br /> 
            Self-Awareness is vital; our mindset, 
            culture, biases and beliefs reflects 
            in the designs we create.  */}
            {/* Self-Awareness as the foundation —
            <br /> */}
            
            Design is a reflection of who we are. Our mindset, 
            culture, biases, and beliefs shape the products and services we create.
            </p>
            <p className="full-textlargebluebg">
            I believe design can drive the creation of a healthier and a happier future. 
            </p>
            </div>

            <div className="img left">
              <img src={about10} alt="" />
            </div>
              
            
      </section>
      </div>
      </div>

      <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">

      {/* <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
              My 'Why?'<br /><br />
              I don’t live to design,
              I design to live!
              </p>
              </div>
            <div className="process-right">
              
              <p>
              Good design simplifies and enriches lives. 
              My aspiration as a designer is to create products and 
              services that add genuine value to people’s lives, 
              making design meaningful for me.
              </p>
              <p>
              Climate change, mental health, well-being, tech addiction, and data exploitation.
              These complex issues require thoughtful, empathetic solutions. 
              
              </p>            
              </div>
      </div> */}

      <section id="content" className="content-wrapper inner">
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            {/* <div className="img left">
              <img src={about1} alt="" />
            </div> */}
            <div className="img left">
              <img src={about5} alt="" />
            </div>
          </div>
        </div>
      </section>


      {/* <p className="full-textlarge">
      I adopt a human-planet-business centric approach, using technology as an enabler.
      </p> */}

      <div className="process">             
            <div className="process-right">
            <p className="half-textlarge">
            I adopt a human-planet-business centric approach, using technology as an enabler.
            </p>
            <p>
            {/* This means designing experiences that create value and well-being for users, 
            are sustainable for the environment, and viable for businesses.  */}
            I aim to create holistic systems where both users and businesses benefit and grow, 
            solving problems in a smart, ethical, and sustainable way.
            </p>           
            </div>
      </div>

      <div className="changebg">
      <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            We are entering the era of AI-enabled services!
            </p>
            </div>
            <div className="process-right">
            
            <p>AI is changing our relationship with technology forever.</p>
              <p>It is ushering in an era of unprecedented services that promises to 
                radically change the way billions of people live, work,
                 and interact with the digital world</p>
            </div>
      </div>



      <section id="content" className="content-wrapper inner">
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            
            <div className="img right">
              <img src={about8} alt="" />
            </div>
          </div>
        </div>
      </section>

      <div className="bluebg">
            <br />
            <p className="full-textlargebluebg">
            
            Do we really need a separate App for every product and service?
            <br />
            <br />
            I envision a future without Apps and the App Store.
            <br />
            <br />
            In this future, personal AI agents will become extensions of individuals, seamlessly popping in and out of different contexts to offer services or autonomously perform complex and repetitive tasks.

            </p>
            <br />
            </div>

      <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            Designer's new role; — 
            to build trust, and to define the elements, 
            boundaries, and goals within which AI operates.
            </p>
              
            </div>
            <div className="process-right">
            <p>Design will play a pivotal role in developing AI systems, shaping
               how we interact with, deploy, evaluate, and trust this new intelligence.
               <br />
               <br />
                This is an exciting time to experiment with innovative interfaces and 
                push the boundaries of existing ones.</p>
            </div>
      </div>

      

            <div className="process">           
            <div className="process-left">
            <p className="half-textlarge">
            Designers must be gatekeepers of what we introduce to society.
            </p>
              
            </div>
            <div className="process-right">
            <p>The possibilities are endless, and our biggest limitation is our imagination. 
               <br />
               <br />
               Having said that, we have a responsibility for the impact our products and services have on users and the enviroment we live in. 
               <br />
               <br />
               Our design choices and decisions are crucial. 
               The user's experience is shaped by these decisions, and we must foresee the unintended consequences our designs may have on society.
               
               </p>
            </div>
      </div>

      </div>

      <br />
      <br />
          


      </section>
      </div>
      </div>
      
      {/* <section id="top-banner-section" className="wo-top-banner inner"> */}
        
        {/* <img src={wo1} alt="" className="tshapeoffer" /> */}
        
        {/* <img src={wo1} alt="" className="left top" /> */}


{/* link to download resume */}

        {/* <div className="resume-download">
          <p>like what you see?</p>
          <a href={resume} className="resume-download-link" download>
            Know More
          </a>
        </div> */}



      {/* </section> */}

      {/* <section id="content" className="wo-content-wrapper">
        <div className="research wrapper">
          <div className="shape" />
          <div className="service-title">RESEARCH</div>
          <div className="service-text">
            Everything starts with understanding behavior. Not just human
            behavior to discover what people want and need, but i also
            understand how entire systems behaves.
          </div>
        </div>
        <div className="strategy wrapper">
          <div className="shape" />
          <div className="service-title">STRATEGY</div>
          <div className="service-text">
            By asking how should people experience a product or brand, and what
            impact it can have in their lives, i define a coherent framework
            around which products & services are designed.
          </div>
        </div>
        <div className="design wrapper">
          <div className="shape" />
          <div className="service-title">DESIGN</div>
          <div className="service-text">
            I shape end-to-end experiences of products and services that connect
            with people and meet their needs - making their lives better,
            simpler, efficient & enjoyable.
          </div>
        </div>
        <div className="zooming wrapper">
          <div className="shape" />
          <div className="service-title">ZOOMING IN & OUT</div>
          <div className="service-text">
            I have the ability to repeatedly step away from the product to think
            more about the system the service is part of and then going closer
            to understand how the details of the product and experience works.
          </div>
        </div>
        <div className="management wrapper">
          <div className="shape" />
          <div className="service-title">MANAGEMENT</div>
          <div className="service-text">
            Good organisation & time management skills that takes into account
            forward planning and strategy, I manage people and delegate talks
            based on their strengths, and assist in clear communication between
            the team.
          </div>
        </div>
        <div className="openmind wrapper">
          <div className="shape" />
          <div className="service-title">... and an open mind!</div>
          <div className="service-text">
            Always open to trying out new things, i have a mind that is
            receptive to new ideas and arguments, with the willingness to listen
            & learn from other people. I believe in "fail fast to innovate
            faster"
          </div>
        </div>
      </section> */}



      <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
      
      
      {/* <div className="changebg">
      <div className="process">           
            <div className="process-left">
              <h4>My core skills</h4>
            </div>
            <div className="process-right">
              <h5>
              <span role="img" aria-labelledby="researchemoji">🔍</span> Research
              </h5>
              <p>
              Everything starts with understanding behaviors. Not just human 
              behavior to discover what people want and need, but i also 
              understand how entire systems behave.  
              </p>
              <br />
              <h5>
              <span role="img" aria-labelledby="strategyemoji">🧠</span> Strategy
              </h5>
              <p>
              By asking how should people experience a product or 
              brand, and what impact it can have in their lives, 
              I define a coherent framework around which products
               & services are designed.  
              </p>
              <br />
              <h5>
              <span role="img" aria-labelledby="designemoji">✏️</span> Design
              </h5>
              <p>
              I shape end-to-end experiences of products and services
               that connect with people and meet their needs - making
                their lives better, simpler, efficient & enjoyable.  
              </p>
              <br />
              <h5>
              <span role="img" aria-labelledby="zoomemoji">👀</span> Zoom in & Zoom out
              </h5>
              <p>
              I can repeatedly step away from the product 
              to think more about the system the service is part of and 
              then going closer to understand how the details of the 
              product and experience work.  
              </p>
              <br />
              <h5>
              <span role="img" aria-labelledby="managementemoji">🗓️</span> Management
              </h5>
              <p>
              Good organisation & time management skills that takes 
              into account planning and strategy, I manage 
              people and delegate tasks based on their strengths, 
              and assist in clear communication between the team.   
              </p>
              <br />
              <h5>
              <span role="img" aria-labelledby="openmindemoji">🌍</span> An open mind...
              </h5>
              <p>
              Always open to trying out new things, I have a mind 
              that is receptive to new ideas and arguments, with 
              the willingness to listen & learn from other people. 
              I believe it's important to learn to 
              unlearn and re-learn. 
              </p>
            </div>
      </div>
      </div> */}


      <div className="process">           
            <div className="process-left">
              <h4>Some of the clients I have worked with</h4>
            </div>
            <div className="process-right">           
               
             

              <div>
              <img src={clientgroup} alt="" />
              </div>
              
            </div>
      </div>




      </section>
      </div>
      </div>

      


      {/* <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
      

      <br />
          <br />
          <div className="changebg">

          <div className="process">           
            <div className="process-left">
              <p>
              Follow me on Medium where I have put 
              down some of my thoughts and mindset 
              towards Design.
              </p>
              </div>
              <div className="process-right">
              <div>
              <img className="protologo" src={mediumlogo} alt="" />
              </div>
              
              <div className="resume-download">
              <a href={"https://medium.com/@sagarsatam"} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
              Read stories
              </a>
              </div> 
                             
              </div>
            </div>
            </div>


      </section>
      </div>
      </div> */}


          
              
      <br />
      


      <section id="footertopcta" className="inner">
        <p className="text arrow-front">
        {/* the below link that is commented takes to the landing page - featured work section */}
        {/* <Link to="/#one"> */}
        <Link to="/#one">
          now that you understand my approach to design,
          check out my featured projects</Link>
        </p>
      </section>

      <br />
    </div>
  </Layout>
)

export default WhatOffer
